export const getDateFromRequest = requestDate => new Date(
  Date.UTC(
    requestDate.year,
    requestDate.monthValue - 1,
    requestDate.dayOfMonth,
    requestDate.hour,
    requestDate.minute,
    requestDate.second
  )
)
