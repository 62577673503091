import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import Keycloak from 'keycloak-js'
import {createMuiTheme} from '@material-ui/core/styles'
import {ThemeProvider} from '@material-ui/core/styles'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import App from './components/App'
import * as serviceWorker from './utils/serviceWorker'
import muiTheme from './styles/muiTheme'
import './styles/index.scss'

const theme = createMuiTheme(muiTheme)

export const BASE_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : window.location.origin
export let BASE_KC_URL

axios.defaults.baseURL = `${BASE_URL}/api`

export const kc = new Keycloak('/keycloak.json')

kc.init({onLoad: 'login-required'}).success((authenticated) => {
  if (authenticated) {
    BASE_KC_URL = `${kc.authServerUrl}/realms/${kc.realm}`
    axios.defaults.headers.common.Authorization = `bearer ${kc.token}`
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>,
      document.getElementById('root')
    )
  }
})

serviceWorker.unregister()
