import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import Loader from '../_common/Loader'
import SystemsSection from './SystemsSection'
import {PUBLIC_SYSTEM_IDS} from '../../utils/constants'

Systems.propTypes = {
  systems: PropTypes.object,
  isSystemsFetch: PropTypes.bool.isRequired,
  isRegistration: PropTypes.bool.isRequired,

  onSystemsFetch: PropTypes.func.isRequired,
  onRegistration: PropTypes.func.isRequired,
}

const sortSystemsByActive = (systems) =>
  systems.sort((a, b) => (a.active && !b.active ? -1 : !a.active && b.active ? 1 : 0))

export default function Systems({systems, isSystemsFetch, isRegistration, onSystemsFetch, onRegistration}) {
  useEffect(() => {
    onSystemsFetch()
  }, []) // eslint-disable-line

  let personalSystems = systems ? systems.applications.filter((system) => !PUBLIC_SYSTEM_IDS.includes(system.id)) : []
  sortSystemsByActive(personalSystems, true)

  const publicSystems = systems ? systems.applications.filter((system) => PUBLIC_SYSTEM_IDS.includes(system.id)) : []
  sortSystemsByActive(publicSystems)

  const commonSectionProps = {
    isSystemsFetch,
    isRegistration,
    onRegistration,
  }

  return (
    <>
      <Loader isFetch={isRegistration} />
      <SystemsSection {...commonSectionProps} isPersonal systems={personalSystems} />
      <SystemsSection {...commonSectionProps} systems={publicSystems} />
    </>
  )
}
