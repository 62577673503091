import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import PersonIcon from '@material-ui/icons/PersonRounded'

import ScrollAnimation from '../_common/ScrollAnimation'
import System from './System'
import {THEME} from '../../styles/muiTheme'
import './SystemsSection.scss'

const styles = {
  icon: {
    color: THEME.PRIMARY,
    marginRight: 8,
  },
  circularProgress: {
    marginLeft: 16,
    width: 30,
    height: 30,
  },
}

const GroupIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
    <path fill="none" d="M0 0H24V24H0z" />
    <path
      d="M12 12.75c1.63 0 3.07.39 4.24.9 1.08.48 1.76 1.56 1.76 2.73V18H6v-1.61c0-1.18.68-2.26 1.76-2.73 1.17-.52 2.61-.91 4.24-.91zM4 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm1.13 1.1c-.37-.06-.74-.1-1.13-.1-.99 0-1.93.21-2.78.58A2.01 2.01 0 0 0 0 16.43V18h4.5v-1.61c0-.83.23-1.61.63-2.29zM20 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4 3.43c0-.81-.48-1.53-1.22-1.85A6.95 6.95 0 0 0 20 14c-.39 0-.76.04-1.13.1.4.68.63 1.46.63 2.29V18H24v-1.57zM12 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3z"
      fill="currentColor"
    />
  </svg>
)

export default class SystemsSection extends Component {
  state = {
    isOpen: false,
    system: null,
  }

  static propTypes = {
    isPersonal: PropTypes.bool,
    systems: PropTypes.array.isRequired,
    isSystemsFetch: PropTypes.bool,
    isRegistration: PropTypes.bool,

    onRegistration: PropTypes.func,
  }

  handleRegistration = () => this.props.onRegistration(this.state.system.id, this.handleDialogClose)

  handleDialogOpen = (system) => () => this.setState({system, isOpen: true})

  handleDialogClose = () => this.setState({isOpen: false})

  render() {
    const {isPersonal, systems, isSystemsFetch, isRegistration} = this.props
    const {isOpen, system} = this.state

    const Icon = isPersonal ? PersonIcon : GroupIcon
    const systemName = system && system.name

    return (
      <>
        <section className="SystemsSection">
          <h2 className="SystemsSection-Title">
            <span className="SystemsSection-TitleText FadeInMedium">
              <Icon style={styles.icon} />
              {isPersonal ? 'Персонализированные' : 'Общедоступные'}
              {isSystemsFetch && <CircularProgress color="primary" style={styles.circularProgress} />}
            </span>
            <ScrollAnimation
              tag="span"
              className={cn('SystemsSection-TitleLabel', {'SystemsSection-TitleLabel_disabled': !isPersonal})}
              effect="fadeInBottom"
            >
              {systems.length}
            </ScrollAnimation>
          </h2>
          <Grid container component="ul" spacing={3}>
            {systems.map((system, index) => (
              <Grid key={system.id} item component="li" xs={12} sm={6} md={4} lg={3}>
                <ScrollAnimation effect="fadeInBottomSmall" duration={THEME.LONG}>
                  <System system={system} onDialogOpen={this.handleDialogOpen} />
                </ScrollAnimation>
              </Grid>
            ))}
          </Grid>
        </section>
        <Dialog open={isOpen} onClose={this.handleDialogClose}>
          <DialogTitle>{systemName}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Для доступа к приложению "{systemName}" необходимо назначение роли администратором.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleDialogClose}>
              Закрыть
            </Button>
            <Button
              autoFocus
              color="primary"
              variant="contained"
              onClick={this.handleRegistration}
              disabled={isRegistration}
            >
              Отправить заявку
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
