import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/PersonRounded'

import logoImg from '../../assets/images/logo.png'
import MenuDropdown from '../_common/MenuDropdown'
import Rate from './Rate'
import {kc} from '../../index'
import {THEME} from '../../styles/muiTheme'
import './style.scss'

const scrollTop = () => window.scrollTo(0, 0)

const useStyles = makeStyles({
  avatar: {
    border: `2px solid ${THEME.PRIMARY}`,
    color: THEME.PRIMARY,
    backgroundColor: 'transparent',
  },
})

Header.propTypes = {
  user: PropTypes.object.isRequired,
}

function Header({user}) {
  const classes = useStyles()
  const userLinks = [
    {
      id: 1,
      text: 'Профиль',
      href: `${kc.authServerUrl}/realms/${kc.realm}/account`,
    },
    {
      id: 2,
      text: 'Выход',
      handler() {
        kc.logout(process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : `${window.location.origin}`)
      },
    },
  ]
  return (
    <AppBar className="Header" color="default" position="static" elevation={0}>
      <div className="Header-Container Container">
        <div className="Header-Content">
          <button className="Header-Logo" onClick={scrollTop}>
            <img className="Header-Image" src={logoImg} width={210} height={19} alt="Vaillant Group" />
          </button>
          <MenuDropdown
            links={userLinks}
            header={
              <li className="Header-User">
                <Avatar className={classes.avatar}>
                  <PersonIcon color="primary" />
                </Avatar>
                <div className="Header-UserInfo">
                  <p>
                    {user.firstName} {user.middleName} {user.lastName}
                  </p>
                  <p className="Header-UserEmail">{user.email}</p>
                </div>
              </li>
            }
          >
            <PersonIcon color="secondary" />
            <span className="Header-UserName">
              {user.firstName} {user.lastName}
            </span>
          </MenuDropdown>
        </div>
        <Rate />
      </div>
    </AppBar>
  )
}

export default React.memo(Header)
