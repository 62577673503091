import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded'

import {THEME} from '../../styles/muiTheme'

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

const transformOrigin = {
  vertical: 'top',
  horizontal: 'center',
}

const paperProps = {
  className: 'MenuDropdown',
}

const styles = {
  button: {
    display: 'flex',
    padding: '6px 12px',
    backgroundColor: THEME.BLOCK,
    color: THEME.SECONDARY,
    '&:hover': {
      backgroundColor: THEME.BLOCK_DARK,
    },
  },
  expandMoreIcon: {
    transition: `transform ${THEME.SHORTER}ms ${THEME.EASE_IN_OUT}`,
  },
  expandMoreIconActive: {
    transform: 'rotate(-180deg)',
  },
}

class MenuDropdown extends Component {
  state = {
    anchorEl: null,
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    header: PropTypes.node,
    links: PropTypes.array.isRequired,
  }

  handleClick = e => this.setState({anchorEl: e.currentTarget})

  handleClose = () => this.setState({anchorEl: null})

  handleItemClick = handler => () => {
    handler()
    this.handleClose()
  }

  renderLinks() {
    return this.props.links.map(link =>
      link.href ? (
        <li key={link.id}>
          <MenuItem component="a" href={link.href} target="_blank" rel="noopener noreferrer" onClick={this.handleClose}>
            {link.text}
          </MenuItem>
        </li>
      ) : (
        <MenuItem key={link.id} onClick={this.handleItemClick(link.handler)}>
          {link.text}
        </MenuItem>
      )
    )
  }

  render() {
    const {children, classes, header} = this.props
    const {anchorEl} = this.state
    const isOpen = Boolean(anchorEl)
    return (
      <>
        <Button className={classes.button} variant="contained" onClick={this.handleClick}>
          {children}
          <ExpandMoreIcon className={cn(classes.expandMoreIcon, {[classes.expandMoreIconActive]: isOpen})} />
        </Button>
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          PaperProps={paperProps}
          open={isOpen}
          onClose={this.handleClose}
        >
          {header}
          {this.renderLinks()}
        </Menu>
      </>
    )
  }
}

export default withStyles(styles)(MenuDropdown)
