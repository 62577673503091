import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import ButtonBase from '@material-ui/core/ButtonBase'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/InfoOutlined'

import {BASE_URL} from '../../index'
import {THEME} from '../../styles/muiTheme'
import './System.scss'

export const serviceClubRoleKey = 'service-club'
export const proExpertRoleKey = 'pro-expert-loyalty'

const StyledPaper = withStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
})(Paper)

const StyledButtonBase = withStyles({
  root: {
    width: '100%',
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: THEME.LIGHT_BLOCK,
    transition: `background-color ${THEME.SHORTER}ms ${THEME.EASE_IN_OUT}`,
    '&:hover': {
      backgroundColor: THEME.LIGHT_BLOCK_DARK,
    },
  },
})(ButtonBase)

const StyledInfoIcon = withStyles({
  root: {
    position: 'absolute',
    right: 4,
    top: 4,
    padding: 4,
    width: 26,
    height: 26,
    color: THEME.PRIMARY_LIGHT,
  },
})(InfoIcon)

System.propTypes = {
  system: PropTypes.object.isRequired,
  isFetch: PropTypes.bool,

  onDialogOpen: PropTypes.func.isRequired,
  onSpecialSystemClick: PropTypes.func,
}

function System({isFetch, system, onDialogOpen}) {
  const img = `${BASE_URL}/img/${system.icon}`

  return (
    <StyledPaper className="System">
      {system.active ? (
        <StyledButtonBase focusRipple component="a" href={system.url} target={'_blank'} disabled={!system.url}>
          <div className="System-ImgBlock">
            <img className="System-Img" src={img} alt={system.name} />
          </div>
        </StyledButtonBase>
      ) : (
        <div className="System-Disabled">
          <div className="System-ImgBlock System-ImgBlock_disabled">
            <img className="System-Img" src={img} alt={system.name} />
          </div>
          <Button fullWidth variant="contained" color="primary" onClick={onDialogOpen(system)} disabled={!system.url}>
            Регистрация
          </Button>
        </div>
      )}
      <Tooltip
        disableFocusListener
        title={
          <p>
            {system.description}
            {!system.url && (
              <>
                <br />
                <span className="LightSuccess">Ожидается подключение</span>
              </>
            )}
          </p>
        }
      >
        <StyledInfoIcon />
      </Tooltip>
    </StyledPaper>
  )
}

export default React.memo(System)
