import React from 'react'
import PropTypes from 'prop-types'
import {withRouter, Switch, Route, Link, Redirect} from 'react-router-dom'
import MuiTabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Systems from '../Systems'
import Requests from '../Requests'
import ROUTES from '../../utils/routes'
import './style.scss'

Main.propTypes = {
  location: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  systems: PropTypes.object,
  isSystemsFetch: PropTypes.bool.isRequired,
  isRegistration: PropTypes.bool.isRequired,
  requests: PropTypes.object,
  isRequestsFetch: PropTypes.bool.isRequired,
  statuses: PropTypes.array,
  isStatusesFetch: PropTypes.bool.isRequired,

  onSystemsFetch: PropTypes.func.isRequired,
  onRegistration: PropTypes.func.isRequired,
  onRequestsFetch: PropTypes.func.isRequired,
  onStatusesFetch: PropTypes.func.isRequired,
  onErrorSnackbarOpen: PropTypes.func.isRequired,
}

function Main({
  location,
  isAdmin,
  systems,
  isSystemsFetch,
  isRegistration,
  requests,
  isRequestsFetch,
  statuses,
  isStatusesFetch,
  onSystemsFetch,
  onRegistration,
  onRequestsFetch,
  onStatusesFetch,
  onErrorSnackbarOpen,
}) {
  const tabsValue = location.pathname === ROUTES.SYSTEMS ? 0 : 1
  return (
    <main className="Main Container">
      <h1 className="Main-Title FadeInBottomLongest">
        <span className="Main-TitleText">
          Единый кабинет<span className="Main-TitleContent"> пользователя</span>
        </span>
        <br />
        <span className="Main-TitleContent_mobile">
          <span className="Main-TitleText">пользователя</span>
          <br />
        </span>
        <span className="Main-TitleText">Vaillant Group</span>
      </h1>
      <nav className="Main-Navigation FadeInRightLongest">
        <MuiTabs value={tabsValue} color="secondary" indicatorColor="secondary">
          <Tab to={ROUTES.SYSTEMS} label="Системы" component={Link} />
          {isAdmin && <Tab to={ROUTES.REQUESTS} label="Заявки" component={Link} />}
          <Tab disabled to={ROUTES.SYSTEMS} label="Аналитика" component={Link} />
        </MuiTabs>
      </nav>
      <div className="Main-Sections FadeInLongestShort">
        <Switch>
          <Route
            exact
            path={ROUTES.SYSTEMS}
            render={(props) => (
              <Systems
                {...props}
                systems={systems}
                isSystemsFetch={isSystemsFetch}
                isRegistration={isRegistration}
                onSystemsFetch={onSystemsFetch}
                onRegistration={onRegistration}
              />
            )}
          />
          {isAdmin && (
            <Route
              exact
              path={ROUTES.REQUESTS}
              render={(props) => (
                <Requests
                  {...props}
                  requests={requests}
                  isRequestsFetch={isRequestsFetch}
                  statuses={statuses}
                  isStatusesFetch={isStatusesFetch}
                  onRequestsFetch={onRequestsFetch}
                  onStatusesFetch={onStatusesFetch}
                  onErrorSnackbarOpen={onErrorSnackbarOpen}
                />
              )}
            />
          )}
          <Redirect to={ROUTES.SYSTEMS} />
        </Switch>
      </div>
    </main>
  )
}

export default withRouter(Main)
